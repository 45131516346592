<template>
  <div></div>
</template>
<script>
  export default {
    props: {
      useTax: Boolean,
      payment: Object,
    },
    mounted() {
      !this.payment.id && (this.payment.installments = this.inflateInstallments())
    },
    methods: {
      inflateInstallments() {
        const position = 0
        const tax = this.getPaymentMethodTax(0)
        const totalAmount = this.payment.amount

        return [{
          position: position,
          installment_count: 1,
          due_date: this.moment(this.payment.due_date),
          payment_date: this.payment.payment_method.change === 'auto' ? this.moment(this.payment.due_date) : null,
          change: this.payment.payment_method.change,
          amount: totalAmount - (totalAmount * (tax/100)),
          installment_value: totalAmount,
          status: this.payment.payment_method.change === 'auto' ? 'paid_out' : 'pending',
          tax,
        }]
      },
      getPaymentMethodTax(position) {
        const installment = this.payment.payment_method.installments.find(el => el.position === position);
        return installment && this.useTax ? installment.value : 0;
      },
    },
    watch: {
      'payment.amount'() {
        this.payment.installments = this.inflateInstallments()
      },
      'payment.due_date'() {
        this.payment.installments = this.inflateInstallments()
      },
      installmentQuantity() {
        this.payment.installments = this.inflateInstallments()
      },
    }
  }
</script>
