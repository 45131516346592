<template>
  <div>
    <PaymentMethod
      v-for="(payment, index) in bill.payments"
      :key="index"
      :useTax="useTax"
      :type="bill.type"
      :splitted="bill.payments.length > 1"
      :validated="validated"
      :payment="payment"
      :paymentMethods="paymentMethods"
      :bankAccounts="bankAccounts"
      :totalAmount="totalAmount"
      :hasMoreOne="bill.payments?.length > 1"
	  :isRecurrence="bill.recurrence"
      @remove="removePaymentOption"
    />

    <div class="payments-footer">
      <b-button
        class="new-payment-button"
        v-if="bill.id === null"
        variant="outline-primary"
        @click="addNewPaymentOption"
      >
        Adicionar forma de pagamento
      </b-button>
      <div v-if="bill.payments && bill.payments.length" class="total">
        <ValueTotal
          v-if="useTax"
          label="Total c/ desc.:"
          :value="getTotalWithTax()"
        />
        <ValueTotal
          v-if="onlendingExtract"
          label="Valor repasse:"
          :value="onlendingValue"
        />
        <ValueTotal
          label="Total:"
          :value="getTotal()"
        />
      </div>
    </div>

  </div>
</template>
<script>
  import { getCurrentClinic } from '@/utils/localStorageManager'
  import PaymentMethod from '@/components/Financial/Payments/PaymentMethod'
  import ValueTotal from '@/components/General/ValueTotal'

  export default {
    components: { PaymentMethod, ValueTotal },
    props: {
      useTax: Boolean,
      validated: Boolean,
      bill: { type: Object, required: true },
      totalAmount: Number,
      onlendingExtract: Boolean,
      onlendingValue: Number
    },

    data() {
      return {
        clinic: getCurrentClinic(),
        paymentMethods: [],
        bankAccounts: [],

        firstTime: true
      }
    },
    created(){
      this.getPaymentMethods()
      this.getBankAccounts()
    },
    mounted(){
      this.bill.payments = [this.getDefaultPaymentMethod()]
      if(this.totalAmount){
        this.bill.payments[0].amount = this.totalAmount
      }
    },
    methods: {
      getDefaultPaymentMethod: () => ({
        payment_method: null,
        amount: 0,
        due_date: null,
        bank_account: null,
        installments: [],
      }),
      addNewPaymentOption() {
        this.bill.payments.push(this.getDefaultPaymentMethod())
      },
      getTotalWithTax() {
        if (!this.bill.payments) {
          return 0
        }
        const total = this.bill.payments.reduce((acc, payment) => {
          acc += payment.installments.reduce((acc,installment) => {
            acc += installment.amount
            return acc
          }, 0)
          return acc
        }, 0)
        return total
      },
      getTotal() {
        if (!this.bill.payments) {
          return 0
        }
        const total = this.bill.payments.reduce((acc, payment) => {
          acc += payment.amount
          return acc
        }, 0)
        return total
      },
      async removePaymentOption(index) {
        if(this.bill.payments[index].id) {
          this.$swal({
            icon: 'question',
            html: `<div class="body-alert">Deseja realmente excluir este método de pagamento? Os parcelamentos internos também serão excluídos e esta ação não poderá ser desfeita.</div>`,
            showCancelButton: true,
            confirmButtonColor: '#F63220',
            confirmButtonText: 'Confirmar',
            cancelButtonText: 'Cancelar',
            reverseButtons: true
          }).then(res => {
            if (res.isConfirmed) {
              this.deletePayment(index, this.bill.payments[index].id)
            }
          })
          return
        }
        this.bill.payments.splice(index, 1)
      },
      getPaymentMethods() {
        this.api.getPaymentMethodsBillToReceive(this.clinic.id, ['out'])
          .then(res => (this.paymentMethods = res.data))
          .catch(err => this.$toast.error(err.message))
      },
      getBankAccounts() {
        this.api.getBankAccountBillToReceive(this.clinic.id)
          .then(res => (this.bankAccounts = res.data))
          .catch(err => this.$toast.error(err.message))
      },
      deletePayment(index, id) {
        this.api.deletePayment(id)
        .then(() => {
          this.$toast.success('Método de pagamento removido com sucesso')
          this.bill.payments.splice(index, 1)
        })
        .catch(err => this.$toast.error(err.message))
      },
    }
  }
</script>
<style lang="scss" scoped>
.total {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}
.payments-footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px dashed var(--neutral-200);
  padding: 20px 0;
}
.new-payment-button {
  color: var(--blue-500);
}
</style>
