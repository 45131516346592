<template>
  <div class="payment-method-content">
    <div class="splitted" v-if="splitted">
      <div>
        Divisão de pagamento #{{$vnode.key + 1}}
      </div>
      <Icon v-if="!payment.id && $vnode.key > 0" tooltip="Remover pagamento">
        <Delete class="delete-icon" @click="$emit('remove', $vnode.key)" />
      </Icon>
    </div>
    <b-row>
      <b-col cols="4">
        <div class="form-group">
          <label for="payment-method">Método de {{ type === 'bill_to_receive' ? 'recebimento' : 'pagamento' }}</label>
          <multiselect
            id="payment-method"
            track-by="id"
            label="name"
            v-model="payment.payment_method"
            :options="paymentMethods"
            :option-height="40"
            :showLabels="false"
            :searchable="false"
            :allowEmpty="false"
            :showNoResults="false"
            placeholder="Selecionar..."
            class="with-border"
            :disabled="!!payment.id && !isRecurrence"
          >
            <template slot="caret">
              <div class="chevron">
                <ChevronDown />
              </div>
            </template>
            <template slot="noOptions"> Nenhuma opção</template>
          </multiselect>
          <div v-if="validated && payment.payment_method === null" class="custom-invalid-feedback">Campo obrigatório</div>
        </div>
      </b-col>
      <b-col cols="4">
        <div class="form-group">
          <label for="amount">Valor</label>
          <money
            id="amount"
            class="form-control"
            v-model="payment.amount"
            :disabled="!!payment.id && !isRecurrence || (totalAmount && !hasMoreOne)"
          />
          <div v-if="validated && payment.amount === null" class="custom-invalid-feedback">Campo obrigatório</div>
        </div>
      </b-col>
      <b-col cols="4">
        <div class="form-group">
          <label for="due-date" id="title-styles">Vencimento</label>
          <date-picker
            id="due-date"
            class="full"
            v-model="payment.due_date"
            format="DD/MM/YYYY"
            placeholder="DD/MM/AAAA"
            :clearable="false"
            :lang="langDatePicker"
            :disabled="!!payment.id && !isRecurrence"
          />
          <div v-if="validated && !payment.due_date" class="custom-invalid-feedback">Campo obrigatório</div>
        </div>
      </b-col>
      <b-col cols="6">
        <div class="form-group">
          <label for="bank-account">Conta bancária</label>
          <multiselect
            id="bank-account"
            track-by="id"
            label="name"
            v-model="payment.bank_account"
            :options="bankAccounts"
            :option-height="40"
            :showLabels="false"
            :searchable="false"
            :allowEmpty="false"
            :showNoResults="false"
            placeholder="Selecionar..."
            class="with-border"
            :disabled="!!payment.id && !isRecurrence"
          >
            <template slot="caret">
              <div class="chevron">
                <ChevronDown />
              </div>
            </template>
            <template slot="noOptions"> Nenhuma opção</template>
          </multiselect>
          <div v-if="validated && payment.bank_account === null" class="custom-invalid-feedback">Campo obrigatório</div>
        </div>
      </b-col>
      <b-col cols="6">
        <div class="form-group">
          <label for="payment">Forma de pagamento</label>
          <multiselect
            id="payment"
            track-by="value"
            label="label"
            v-model="installmentQuantity"
            :options="installmentQuantityOptions"
            :option-height="40"
            :showLabels="false"
            :searchable="false"
            :allowEmpty="false"
            :showNoResults="false"
            placeholder="Selecionar..."
            class="with-border"
            :disabled="!!payment.id && !isRecurrence || !payment.payment_method || !payment.amount || !payment.due_date"
          >
            <template slot="caret">
              <div class="chevron">
                <ChevronDown />
              </div>
            </template>
            <template slot="noOptions"> Nenhuma opção</template>
          </multiselect>
          <div v-if="validated && installmentQuantity === null" class="custom-invalid-feedback">Campo obrigatório</div>
        </div>
      </b-col>
    </b-row>

    <CashMethod
      v-if="payment.amount && installmentQuantity && payment.payment_method.type === 'cash'"
      :useTax="useTax"
      :payment="payment"
    />

    <PixMethod
      v-if="payment.amount && installmentQuantity && payment.payment_method.type === 'pix'"
      :useTax="useTax"
      :payment="payment"
    />

    <CardMethod
      v-if="payment.amount && installmentQuantity && installmentQuantity.value > 1 && payment.payment_method.type === 'card'"
      :useTax="useTax"
      :installmentQuantity="installmentQuantity.value"
      :payment="payment"
      :paymentMethod="payment.payment_method"
    />

    <DebitMethod
      v-if="payment.amount && installmentQuantity && installmentQuantity.value === 1 && payment.payment_method.type === 'card'"
      :useTax="useTax"
      :payment="payment"
    />

    <BankSlipMethod
      v-if="payment.amount && installmentQuantity && payment.payment_method.type === 'bank_slip'"
      :useTax="useTax"
      :installmentQuantity="installmentQuantity.value"
      :payment="payment"
      :paymentMethod="payment.payment_method"
    />

    <CheckMethod
      v-if="payment.amount && installmentQuantity && payment.payment_method.type === 'check'"
      :useTax="useTax"
      :installmentQuantity="installmentQuantity.value"
      :payment="payment"
      :paymentMethod="payment.payment_method"
    />

    <AccountDebitMethod
      v-if="payment.amount && installmentQuantity && payment.payment_method.type === 'debit'"
      :useTax="useTax"
      :installmentQuantity="installmentQuantity.value"
      :payment="payment"
    />

    <DebitCardMethod
      v-if="
        payment.amount &&
        installmentQuantity &&
        payment.payment_method.type === 'debit_card'
      "
      :useTax="useTax"
      :installmentQuantity="installmentQuantity.value"
      :payment="payment"
      :paymentMethod="payment.payment_method"
    />

  </div>
</template>
<script>
  import Icon from '@/components/General/Icon'
  import ChevronDown from '@/assets/icons/chevron-down.svg'
  import Delete from '@/assets/icons/delete.svg'
  import CashMethod from '@/components/Financial/Payments/CashMethod'
  import PixMethod from '@/components/Financial/Payments/PixMethod'
  import CardMethod from '@/components/Financial/Payments/CardMethod'
  import DebitMethod from '@/components/Financial/Payments/DebitMethod'

  import BankSlipMethod from '@/components/Financial/Payments/BankSlipMethod'
  import CheckMethod from '@/components/Financial/Payments/CheckMethod'
  import AccountDebitMethod from '@/components/Financial/Payments/AccountDebitMethod'

  export default {
    components: {
      Icon, ChevronDown, Delete,
      PixMethod, CashMethod, CardMethod, DebitMethod,
      BankSlipMethod, CheckMethod, AccountDebitMethod,
      DebitCardMethod: () => import('@/modules/financial/components/paymentMethods/DebitCardMethod')
    },
    props: {
      useTax: Boolean,
      type: String,
      splitted: Boolean,
      validated: Boolean,
      payment: Object,
      paymentMethods: Array,
      bankAccounts: Array,
      totalAmount: Number,
      hasMoreOne: Boolean,
      isRecurrence: Boolean
    },
    data() {
      return {
        installmentQuantity: null,
        installmentQuantityOptions: [],
        langDatePicker: {
          formatLocale: {
            weekdaysMin: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb']
          }
        }
      }
    },
    mounted() {
      this.payment.payment_method && this.getInstallmentCountOptions()
    },
    methods: {
      getInstallmentCountOptions() {
        const options = new Array(this.payment.payment_method.installments.length || 1)
          .fill({})
          .reduce((acc, el, index) => {
            acc.push(({ label: this.getPaymentQuantityOptionLabel(index), value: (index + 1) }))
            return acc;
          }, [])
        this.installmentQuantityOptions = options
        if (
          this.installmentQuantityOptions.length === 1 &&
          this.installmentQuantityOptions[0].label === 'À vista'
        ) {
          this.installmentQuantity = this.installmentQuantityOptions[0]
        } else {
          this.installmentQuantity = this.payment.installments.length ? options[this.payment.installments.length-1] : null
        }
        this.changeDateTypeIfNecessary()
      },
      getPaymentQuantityOptionLabel(index) {
        switch(this.payment.payment_method.type) {
          case 'cash':
          case 'pix':
          case 'debit':
          case 'debit_card':
            return 'À vista'
          case 'card':
            return index === 0 ? 'Débito' : `${index}x`
          case 'bank_slip':
          case 'check':
            return `${index+1}x`
          default:
            return 'À vista'
        }
      },
      changeDateTypeIfNecessary() {
        if (this.payment && this.payment.id && this.payment.due_date && typeof this.payment.due_date === 'string')  {
          this.payment.due_date = this.moment(this.payment.due_date).toDate()
        }
      }
    },
    watch: {
      'payment.payment_method': function(val) {
        val && this.getInstallmentCountOptions()
      },
    }
  }
</script>
<style lang="scss" scoped>
.payment-method-content {
  border-top: 1px solid var(--neutral-200);
  padding-top: 20px;
  margin: 10px 0;
}
.splitted {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 700;
  font-size: min(1.2vw, 16px);
  color: var(--neutral-700);
  background-color: var(--neutral-100);
  border-radius: 8px;
  padding: 8px 10px;
  margin-bottom: 15px;
}
.delete-icon {
  cursor: pointer;
}
</style>
