<template>
  <div class="amount-position">
    <div>
      <p>{{ label }} <span>R${{ parseNumberToMoney(value).trim().split('$')[1]}} </span></p>
    </div>
  </div>
</template>
<script>
import { parseNumberToMoney } from '@/utils/moneyHelper'
export default {
  props: ['label', 'value'],
  methods: {
    parseNumberToMoney,
  }
}
</script>
<style lang="scss" scoped>
.amount-position {
  display: flex;

  div {
    padding: 8px 16px;
    background: var(--blue-100);
    border-radius: 8px;
    margin-left: 10px;
    justify-content: center;

    p {
      font-size: 16px;
      margin-bottom: 0px;
      color: var(--type-active);

      span {
        font-weight: 700;
        color: var(--blue-500);
      }
    }
  }
}

.green-total div {
  background: var(--states-success-soft-green) !important;
}
</style>
