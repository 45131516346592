<template>
  <b-table-simple striped>
    <tbody>
      <tr>
        <th>Parcela(s)</th>
        <th>Vencimento</th>
        <th>Pagamento</th>
        <th>Pago em</th>
        <th>Valor</th>
      </tr>
      <tr v-for="(installment, index) in installments" :key="index">
        <td class="td-installments">
          {{ `Parcela ${['bank_slip', 'check'].includes(type) ? installment.position + 1 : installment.position}/${installments.length}` }}
        </td>
        <td v-if="customDueDate && installments.length > 1">
          <date-picker
            id="due-date"
            class="full"
            v-model="installment.due_date"
            format="DD/MM/YYYY"
            placeholder=""
            :clearable="false"
            :lang="langDatePicker"
            :disabled="index === 0 || installment.status === 'paid_out'"
          />
        </td>
        <td v-else>{{ installment.due_date ? moment(installment.due_date).format('DD/MM/YY') : '-' }}</td>
        <td>
          <div class="radios-change">
            <div class="change-input">
              <input
                class="radio-input"
                type="radio"
                :name="`change-${installment.position}`"
                :disabled="installment.status === 'paid_out'"
                value="auto"
                v-model="installment.change"
                @input="el => installment.id ? updateChange(installment.id, el.target.value) : null"
              />Auto
            </div>
            <div class="change-input">
              <input
                class="radio-input"
                type="radio"
                :name="`change-${installment.position}`"
                :disabled="installment.status === 'paid_out'"
                value="manual"
                v-model="installment.change"
                @input="el => installment.id ? updateChange(installment.id, el.target.value) : null"
              />Manual
            </div>
          </div>
        </td>
        <td>{{ installment.payment_date ? moment(installment.payment_date).format('DD/MM/YYYY') : '-' }}</td>
        <td class="td-installment-value">
          {{ parseNumberToMoney(installment.amount) }}
        </td>
      </tr>
      <tr class="row-total">
        <th colspan="4" class="total-title">
          Total a {{ useTax ? 'receber' : 'pagar'}}
          <span class="tax" v-if="useTax"> (taxa: {{ installments.length && (type === 'bank_slip' ? parseNumberToMoney(installments[0].tax) : `${installments[0].tax ? installments[0].tax.toString().replace('.', ',') : '0'}%`) }})</span>
        </th>
        <th class="total">
          {{ parseNumberToMoney(getTotal()) }}
        </th>
      </tr>
    </tbody>
  </b-table-simple>
</template>
<script>
  import { EventBus } from '@/utils/eventBus'
  import { parseNumberToMoney } from '@/utils/moneyHelper'
  export default {
    props: {
      type: String,
      useTax: Boolean,
      installments: Array,
      customDueDate: {
        type: Boolean,
        default: false
      },
    },
    data () {
      return {
        langDatePicker: {
          formatLocale: {
            weekdaysMin: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb']
          }
        }
      }
    },
    methods: {
      parseNumberToMoney,
      getTotal() {
        if(!this.installments || !this.installments.length) return 0;
        return this.installments.reduce((acc, installment) => {
          acc += installment.amount;
          return acc;
        }, 0);
      },
      async updateChange(id, value) {
        try {
          await this.api.updateInstallment(id, { change: value })
          EventBus.$emit('reloadBillsToReceive')
          EventBus.$emit('reloadBillsToPay')
          this.$toast.success('Alterado com sucesso')
        } catch (err) {
          this.$toast.error(err.message)
        }
      }
    },
  }
</script>
<style lang="scss" scoped>
.radios-change {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  .change-input {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
  }
}
th {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: var(--type-active) !important;
  padding: 20px 10px;
  border: none !important;
}
td {
  border: none !important;
  padding: 20px 10px;
  font-weight: normal;
  font-size: 16px;
  vertical-align: middle !important;
  border-top: 1px dashed var(--neutral-300);
  color: var(--type-active);
  font-family: 'Nunito Sans';
}
.td-installments {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: var(--neutral-700);
}
.td-installment-value {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: var(--neutral-700);
}
.radio-input {
  width: 24px;
  height: 24px;
}
.row-total {
  vertical-align: middle;
  background-color: #F8F8FD !important;
}
.total-title {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  padding: 20px 0.75rem !important;
  border-top: 1px solid #dee2e6 !important;
  border-bottom: 1px solid #dee2e6 !important;
  color: var(--type-active);
}
.total {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  border-top: 1px solid #dee2e6 !important;
  border-bottom: 1px solid #dee2e6 !important;
  color: var(--neutral-700) !important;
}
.tax {
  font-size: 12px;
}
</style>
