<template>
  <div></div>
</template>
<script>
  export default {
    props: {
      payment: Object,
    },
    mounted() {
      !this.payment.id && (this.payment.installments = this.inflateInstallments())
    },
    methods: {
      inflateInstallments() {
        return [{
          position: 0,
          installment_count: 1,
          change: this.payment.payment_method.change,
          amount: this.payment.amount,
          installment_value: this.payment.amount,
          due_date: this.moment(this.payment.due_date),
          payment_date: this.payment.payment_method.change === 'auto' ? this.moment(this.payment.due_date) : null,
          status: this.payment.payment_method.change === 'auto' ? 'paid_out' : 'pending',
          tax: 0
        }]
      },
    },
    watch: {
      'payment.amount'() {
        this.payment.installments = this.inflateInstallments()
      },
      'payment.due_date'() {
        this.payment.installments = this.inflateInstallments()
      },
      installmentQuantity() {
        this.payment.installments = this.inflateInstallments()
      }
    }
  }
</script>
